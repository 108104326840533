import React from "react"
import Layout from "../components/layout"
import { useLocation } from '@reach/router'
import ShareButtons from "../components/ShareButtons"
import { GatsbyImage, getImage} from "gatsby-plugin-image"
import ImageGallery from 'react-image-gallery'




import Seo from "../components/seo"
import { graphql, Link } from "gatsby"


const BlogDetail = ({data}) => {
    const location = useLocation();
    const get_location = location.href
    //console.log('Single',get_location )
    const post_detial = data.wpPost
    console.log('post detail', post_detial)

    return (

        <Layout>

        <Seo title={post_detial.title} />
        
        <section className = "post_detail">
            
                <div className="container">
                  <h1>{post_detial.title}</h1>

               <div className = "posted_info">

                 <div className = "date_info">

                <div className="top_in"> <div><i className="fa fa-clock-o" ></i> <span dangerouslySetInnerHTML={{ __html: post_detial.excerpt }}></span></div>

                   <div>  <i className="fa fa-calendar"></i><span> {post_detial.date}</span></div>
                   </div>
                   <ShareButtons title={post_detial.title} url={get_location}/>

                 </div>

               </div>

                <div dangerouslySetInnerHTML={{ __html: post_detial.content}}></div>
                  
                

{
post_detial.tags.nodes != "" ?

<div className = "tags_list">
<h3>Similar Tags:</h3>
<ul>
{

post_detial.tags.nodes.map((tag) => (



<li><Link to = {`/tag/${tag.slug}`}>{tag.name}</Link></li>

))}


</ul>

</div>



:




''

}
              
                    
            </div>
          
      
   

   
        </section>
        
        
        </Layout>

    )

}



export const BlogPost_single = graphql`

query Singleblogspost($slug: String) {
  
    wpPost(slug:{eq: $slug}) {
           
               
                title
                slug
                content
                excerpt
                date(formatString: "DD MMMM, YYYY")
                featuredImage {
                  node {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(layout: CONSTRAINED, quality: 100, placeholder: BLURRED,width:520)
                      }
                    }
                  }
                }
                tags {
                  nodes {
                    name
                    slug
                  }
                }

              }
          
  }


`




export default BlogDetail;